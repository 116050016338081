<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader> <strong>Stocks</strong> </CCardHeader>
        <CCardBody>
          <div class="table-responsive">
            <table class="table table-hover table-sm">
              <thead>
                <tr>
                  <th scope="col" class="text-left">Product Code</th>
                  <th scope="col" class="text-left">Name</th>
                  <th scope="col" class="text-center">Price</th>
                  <th scope="col" class="text-center">Purchase Qty</th>
                  <th scope="col" class="text-center">Sale Qty</th>
                  <th scope="col" class="text-center">Available Stock</th>
                  <th scope="col" class="text-center">Action</th>
                </tr>
              </thead>
              <tbody class="text-center pt-5" v-if="isLoading">
                <tr>
                  <td class="text-center" colspan="8">
                    <CSpinner color="primary" size="lg" />
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="product in products" :key="product.id">
                  <td class="text-left">
                    {{ product.product_code }}
                  </td>
                  <td class="text-left">
                    {{ product.name }}
                  </td>
                  <td class="text-center">
                    {{ product.price }}
                  </td>
                  <td class="text-center">
                    {{ product.purchase_qty }}
                  </td>
                  <td class="text-center">
                    {{ product.sale_qty }}
                  </td>
                  <td class="text-center">
                    {{ product.available_stock }}
                  </td>
                  <td class="text-center">
                    <CButtonGroup size="sm">
                      <CButton
                        color="primary"
                        @click="togglePurchaseHistoryModal(product)"
                      >
                        Purchase History</CButton
                      >
                      <CButton
                        color="warning"
                        @click="toggleShowBarcodeModal(product)"
                      >
                        Show Barcode
                      </CButton>
                    </CButtonGroup>
                  </td>
                </tr>
              </tbody>
            </table>
            <CPagination
              v-if="!isLoading"
              :activePage.sync="pagination.current"
              :pages="pagination.last"
              align="center"
              @update:activePage="getResults"
            />
          </div>
        </CCardBody>
      </CCard>
    </CCol>
    <StockPurchaseHistoryModal
      :data="selected_entry"
      @close="togglePurchaseHistoryModal"
      :show="show_modal_history"
    />
    <StockBarcodeModal
      :data="selected_entry"
      @close="toggleShowBarcodeModal"
      :show="show_modal_barcode"
    />
  </CRow>
</template>

<script>
import { mapState } from "vuex";
const StockPurchaseHistoryModal = () =>
  import("@/views/Stock/StockPurchaseHistoryModal");
const StockBarcodeModal = () => import("@/views/Stock/StockBarcodeModal");

export default {
  name: "Stocks",
  components: {
    StockPurchaseHistoryModal,
    StockBarcodeModal,
  },
  created() {
    if (!this.$store.state.access_token) {
      this.$router.push({ name: "Login" });
    }
    this.$store.dispatch("stocks/getStocks");
  },
  data() {
    return {
      show_modal_history: false,
      show_modal_barcode: false,
      selected_entry: {},
    };
  },
  computed: {
    ...mapState({
      pagination: (state) => state.stocks.pagination,
      products: (state) => state.stocks.stock_products,
      isLoading: (state) => state.stocks.isLoading,
      deleteModal: (state) => state.stocks.deleteModal,
      showModal: (state) => state.stocks.showModal,
    }),
  },
  methods: {
    getResults(page = 1) {
      this.$store.dispatch("stocks/getStocks", page);
    },
    togglePurchaseHistoryModal(product) {
      product && (this.selected_entry = product);
      this.show_modal_history = !this.show_modal_history;
    },
    toggleShowBarcodeModal(product) {
      product && (this.selected_entry = product);
      this.show_modal_barcode = !this.show_modal_barcode;
    },
  },
};
</script>
